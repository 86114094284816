<template>
    <div class="feat-container">
      <div class="feature-carousel-container">
        <div class="feature-slider-header">
          <div class="feature-slider-head">
            <div class="feature-slider-head-btns">
              <div :id="`feature-slider-controls`" class="feature-slider-controls">
                <!-- previous-arrow -->
                <button type="button" class="feature-prev" @click="sliderPrevClicked()">
                  <img src="@/assets/icons/less-than-filled-50.png" width="30px" alt="prev" />
                </button>
                <!-- next-arrow -->
                <button type="button" class="feature-forward" @click="sliderNextClicked()">
                  <img src="@/assets/icons/more-than-filled-50.png" width="30px" alt="for" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="featured-carousel" id="featured-carousel">
          <div  class="feat-card" v-for="(item, key) in contents" :key="key">
            <div tabindex="1" class="feat-card-container">
              <div
                class="feat-card-box-contain"
                :class="[localDisplayLang == 'ara' ? 'feat-card-box-contain-ara' : '']"
                id="card-${key}"
                :item="item"
                :objectid="item.objectid"
                :style="[{ 'background-image': `url(${getPoster(item)})` }, { 'padding-top': `56.25%` }]"
                @click="playContentWrapper(item, $event)"
              >
                <div
                  :class="[localDisplayLang === 'ara' ? 'content-tag-right' : 'content-tag']"
                  v-if="isObjectTag(item)"
                >
                  <div  class="content-tag-item">
										<img v-if="pickTagForContent(item).key == 'PREMIUM'" src="@/assets/icons/Premium_1_scg.svg" alt="crown">
                    <p class="extra-tag-feature" v-else> {{ pickTagForContent(item).value }} </p>
                  </div>
                </div>

                <div class="card-text" id="card-text" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
                  <div class="card-title">
                    <p style="font-size: 1.1rem;font-weight: 800;">{{ item.title.toUpperCase() }}</p>
                  </div>

                  <div class="tags"
                 
                  >
                  
                    <p>{{ $t(item.tags && item.tags.toString().toLowerCase().includes('ct-music') ? 'Music' : item.category == 'TVSHOW' ? 'SERIES' : item.category) }}</p>
                   <div
                   >
                    <p 
                    >{{ $t(item.genre) }}</p>
                   </div>
                    <!-- <p v-if="item.hasOwnProperty('contentlanguage') && item.contentlanguage[0]">
                      {{ $t(item.contentlanguage[0].toLowerCase()) }}
                    </p> -->
                    <!-- <p>{{ item.productionyear }}</p> -->
                    <p dir="ltr" class="pg-rating" v-if="item.pgrating">
                      {{ item.pgrating }}
                    </p>
                  </div>
                  <div class="duration-views" v-if="item.category === 'MOVIE' || (item.tags && item.tags.includes('CT-Music'))">
                    <div class="duration-views-eng" v-if="localDisplayLang !== 'ara'" dir="ltr">
                      <img src="@/assets/icons/Duration.svg" alt="items-count" />
                      <p class="duration-views-eng-number w-100">
                        {{ calcTimeFormat(item.duration) }}
                      </p>
                    </div>
                    <div class="duration-views-ara" dir="ltr" v-else>
                      <img src="@/assets/icons/Duration.svg" alt="items-count" />
                      <p class="duration-views-eng-number w-100">
                        {{ calcTimeFormat(item.duration) }}
                      </p>
                    </div>
                    <!-- <p>{{ item.views }}</p> -->
                  </div>
                  <div class="duration-views" v-if="item.category === 'TVSHOW' && !(item.tags && item.tags.includes('CT-Music'))">
                    <img src="@/assets/icons/season-light.svg" alt="items-count" />
                    <p class="duration-views-number">
                      {{ item.seasoncount }}
                      {{ item.seasoncount === 1 ? $t("Season") : $t("Seasons") }}
                    </p>
                    <!-- <p>{{ item.views }}</p> -->
                  </div>
                  <!-- <div class="card-description" v-if="item.shortdescription">
                    <p v-if="item.shortdescription.length <= 100">
                      {{ item.shortdescription }}
                    </p>
                    <p v-else>
                      {{ item.shortdescription.substring(0, 100) }}...
                    </p>
                  </div> -->

                  <div class="content-action-btns" v-if="item.hasOwnProperty('objectid')">
                    <div class="activity-buttons">
                      <LikeBtn :content="item" :width="'25px'" :height="'25px'" class="hover-change"></LikeBtn>
                      <div class="triangle" :class="triangleColor"></div>
                      <div class="tooltip" :class="toolTipColor">
                        <span>{{ $t("like") }}</span>
                      </div>
                    </div>
                    <!-- <div class="activity-buttons">
                      <DislikeBtn :content="item" :width="'25px'" :height="'25px'" class="hover-change"></DislikeBtn>
                      <div class="triangle" :class="triangleColor"></div>
                      <div class="tooltip" :class="toolTipColor">
                        <span>{{ $t("dislike") }}</span>
                      </div>
                    </div> -->
                    <div class="activity-buttons">
                      <detailIcon :content="item" :width="'25px'" :height="'25px'" class="hover-change"></detailIcon>
                      <div class="triangle" :class="triangleColor"></div>
                      <div class="tooltip" :class="toolTipColor">
                        <span>{{ $t("share") }}</span>
                      </div>
                    </div>
                    <div class="activity-buttons">
                      <WatchListBtn
                        :content="item"
                        :width="'25px'"
                        :height="'25px'"
                        class="hover-change"
                      ></WatchListBtn>
                      <div class="triangle" :class="triangleColor"></div>
                      <div class="tooltip" :class="toolTipColor">
                        <span>{{ $t("Watchlist") }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="play-btn-container">
                  <!-- <img
                    class="play-btn"
                    src="@/assets/icons/Ic_Play1.svg"
                    alt
                    @click="playContent(item)"
                  />-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="feat-nav-container" id="feat-nav-container">
          <button
            class="nav-button"
            type="button"
            v-for="(item, key) in contents"
            :key="key"
            @click="navBtnClicked(key)"
          ></button>
        </div>
      </div>
    </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider.module";
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { _projectName } from "@/provider-config.js";
export default {
  name: "FeaturedCarousel",
  props: {
    featuredScreen: {
      type: Object,
    },
    contents: {
      type: Array,
    },
  },
  data() {
    return {
      // enableFeatured: false,
      cardHeight: 460,
      slider: null,
      triangleColor: null,
      toolTipColor: null,
      localDisplayLang: null,
      counter: 1,
    };
  },
  computed: {
    ...mapGetters([
      "getToken",
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "getRtl",
      "getDisplayLanguageCode",
      "appConfig",
    ]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;

     if (window.screen.width > 1700) {
      this.cardHeight = 510
     }

    this.actChangeBackgroundCarousel();

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.actChangeBackgroundCarousel();
    });
    // tooltip color
    this.toolTipColor =
      _projectName === "NET5" || _projectName === "VLIVE" || _projectName === "Noor Play"
        ? "tooltip-color"
        : _projectName === "NAMMAFLIX"
        ? "tooltip-color-white"
        : "";

    // tooltip triangle Color change
    this.triangleColor =
      _projectName === "NET5" || _projectName === "VLIVE" || _projectName === "Noor Play"
        ? "triangle-color"
        : _projectName === "NAMMAFLIX"
        ? "triangle-color-white"
        : "";
  },
  mounted() {

    this.renderCarousel();
  },
  methods: {
    ...mapActions(["getScreenContents"]),
    actChangeBackgroundCarousel() {
      let deg = this.localDisplayLang == "eng" ? "90deg" : "-90deg";
      document.documentElement.style.setProperty("--backgroud-carousel-color-rotation", deg);
    },
    isObjectTag(content) {
   
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "mal") {
          return { key: tag, value: contenttags.ml[`${tag}`]} ;
        } else {
          return { key: tag, value: contenttags.en[`${tag}`]} ;
        }
      }
    },
    playContentWrapper(item, e) {

      localStorage.setItem("playback_source", "Featured");
      this.playContent(item, e);
    },
    carouseldata(e) {
      let objectid =
        e.srcElement && e.srcElement.attributes.objectid && e.srcElement.attributes.objectid.value
          ? e.srcElement.attributes.objectid.value
          : "";

      let item = this.contents.filter((data) => {
        if (data.objectid == objectid) return data;
      });
      this.playContent(item[0], null);
    },
    //fetch featured carousel card height.
    fetchCardHeight() {
      let bodyWidth = document.body.offsetWidth - 600;
      let cardHeight = Math.round(bodyWidth / (16 / 9));
      if (cardHeight) {
        this.cardHeight = cardHeight + 80;
        if (this.isTablet()) {
          this.cardHeight += 50;
        }
      }
    },

    //fetch poster for featured content.
    getPoster(content) {
      // console.log("content", content);
      if (Array.isArray(content.poster)) {
        if (content.poster && content.poster.length) {
          let index = content.poster.findIndex((element) => {
            return (element.quality === "HD" || element.quality === "SD") && element.postertype === "LANDSCAPE";
          });
          // console.log("getPoster", index);
          if (index > -1) {
            return screen.width > 768
              ? content.poster[index].filelist[1].filename
              : content.poster[index].filelist[1].filename;
          }
        } else {
          return "";
        }
      } else {
        if (content.poster) {
          return this.localDisplayLang === "ara" ? content.poster.LANDSCAPE.ar : content.poster.LANDSCAPE.en;
        }
      }
    },

    //play item from featured carousel.
    playContent(item, e) {
      if (e && (e.target.nodeName == "svg" || e.target.nodeName == "path")) {
        console.log("play content aborted : ", item);
        return;
      }
      if (item.hasOwnProperty("deeplink") && item.deeplink) {
        let id = item.deeplink.split("/")[3];
        if (id == "plan") {

            let planid = item.deeplink.split("/")[4];
            let coupon= "";

            if(item.deeplink.split("/")[5]) {
              coupon = item.deeplink.split("/")[5]
            }

            const routeParams = {
              planId: planid,
            }

            if(coupon) {
              routeParams.coupon = coupon;
            }

            this.$router.push({
              name: "planandcoupondeeplink",
              params: routeParams,
            });
          // }
        } else {
          this.$router.push({ name: "screen", params: { section: `${id}` } });
        }
      } else if (item.hasOwnProperty("objectid")) {
        let currentLanguage = this.getCurrentLanguageUrlBase();

        if (screen.width > 0) {
          if (item.hasOwnProperty("objectid")) {
  
            if (item.category == "TVSHOW") {
         
               let title = this.spacialCharEncoding(item.defaulttitle);

              this.$router.push({
                name: "detailPage",
                params: { contentId: item.objectid.toLowerCase(), mediaTitle: title.toLowerCase(), lang: currentLanguage },
              });
            } else {
               let title = this.spacialCharEncoding(item.defaulttitle);
              this.$router.push({
                name: "detailPageMovie",
                params: { mediaTitle: title.toLowerCase(), contentId: item.objectid.toLowerCase(), lang: currentLanguage },
              });
            }
          }
        } else {
          //this.$router.push({ name: "detail", params: { contentid: item.objectid } });
        }
      }
    },

    //construct carousel for featured contents.
    renderCarousel() {
      if (document.getElementById("featured-carousel")) {
        this.$nextTick(() => {
          if (this.slider) return;
          if (!document.getElementById("featured-carousel")) return;

          let tanyPros = {
            startIndex: 0,
            autoplayDirection: "forward",
          };

          if (this.localDisplayLang == "ara") {
            tanyPros = {
              startIndex: this.contents.length - 1,
              autoplayDirection: "backward",
            };
          }
          const tnsPayload = {
            container: "#featured-carousel",
            navContainer: "#feat-nav-container",
            controlsContainer: "#feature-slider-controls",
            slideBy: 1,
            autoplay: false,
            autoplayHoverPause: true,
            autoplayButtonOutput: false,
            autoHeight: true,
            freezable: false,
            loop: false,
            rewind: false,
            // controls: false,
            nav: true,
            speed: 400,
            items: 1,
            startIndex: tanyPros.startIndex,
            autoplayDirection: tanyPros.autoplayDirection,
            gutter: 5,
            viewportMax: true,
            responsive: {
              // 1350: {
              //   items: 1,
              //   gutter: 50,
              //   edgePadding: 300
              // },
              1200: {
                items: 1,
                gutter: 10,
                edgePadding: 320,
              },
              1024: {
                items: 1,
                gutter: 10,
                edgePadding: 150,
              },
              768: {
                items: 1,
                gutter: 10,
                edgePadding: 25,
              },
              576: {
                items: 1,
                gutter: 10,
                edgePadding: 25,
              },
            },
          };
          
          if (this.contents.length >= 2) {
            tnsPayload.loop = true;
          }
          this.slider = tns(tnsPayload);
        });
      }
    },
    //button navigator for featured carousel.
    navBtnClicked(key) {
      if (this.slider) {
        this.slider.play();
      }
    },
    sliderPrevClicked() {
      const info = this.slider.getInfo();
      this.slider.goTo(info.displayIndex - 1);
      setTimeout(() => {
        this.slider.play();
      }, 100);
    },
    sliderNextClicked() {
      const info = this.slider.getInfo();
      this.slider.goTo(info.displayIndex - 1);
      setTimeout(() => {
        this.slider.play();
      }, 100);
    },
  },
  components: {
    LikeBtn: () => import(/* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"),
    WatchListBtn: () => import(/* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"),
    detailIcon: () => import(/* webpackChunkName: "detailicon" */ "@/components/SvgImages/detailIcon.vue"),
  },
  mixins: [Utility],
};
</script>
<style lang="scss">
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "@/sass/_variables.scss";
@import"./FeaturedCarousel.scss";
</style>
